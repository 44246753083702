<template>
	<div>
		<!-- <v-row class="text-center summary" dense>
			<v-col cols="12" md="">
				<v-card color="teal" dark flat @click="filterBind(sellStep.PRICE_CALCULATED, true)">
					<template slot="progress">
						<v-progress-linear height="4" indeterminate></v-progress-linear>
					</template>
					<div class="d-flex flex-no-wrap justify-space-between align-center" :class="boxPadding">
						<div class="text-center align-center d-flex">
							<v-avatar color="white" :size="avatarSize">
								<v-icon v-text="'fas fa-user'" color="teal" :size="iconSize"></v-icon>
							</v-avatar>
							<span class="ml-5 subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Fiyat Alan (Bu Ay)</span>
						</div>
						<div class="text-right text-no-wrap summary__content">
							<div>
								<h1 class="mb-1" :style="{ fontSize: boxTitleFontSize }">
									{{ summary.monthlyRequest }}
								</h1>
								<div class="subtitle-1 text-uppercase font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
									Fiyat Alan (Bu Ay)
								</div>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col cols="12" md="">
				<v-card color="primary" dark flat @click="filterBind(sellStep.COMPLETED, true)">
					<template slot="progress">
						<v-progress-linear height="4" indeterminate></v-progress-linear>
					</template>
					<div class="d-flex flex-no-wrap justify-space-between align-center" :class="boxPadding">
						<div class="text-center align-center d-flex">
							<v-avatar color="white" :size="avatarSize">
								<v-icon v-text="'fas fa-user-check'" color="primary" :size="iconSize"></v-icon>
							</v-avatar>
							<span class="ml-5 subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Tamamlanan (Bu Ay)</span>
						</div>
						<div class="text-right text-no-wrap summary__content">
							<div>
								<h1 class="mb-1" :style="{ fontSize: boxTitleFontSize }">
									{{ summary.monthlyApproved }}
								</h1>
								<div class="subtitle-1 text-uppercase font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
									Tamamlanan (Bu Ay)
								</div>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col cols="12" md="">
				<v-card color="red" dark flat @click="filterBind(sellStep.CANCELLED, true)">
					<template slot="progress">
						<v-progress-linear height="4" indeterminate></v-progress-linear>
					</template>
					<div class="d-flex flex-no-wrap justify-space-between align-center" :class="boxPadding">
						<div class="text-center align-center d-flex">
							<v-avatar color="white" :size="avatarSize">
								<v-icon v-text="'fas fa-user-times'" color="red" :size="iconSize"></v-icon>
							</v-avatar>
							<span class="ml-5 subtitle-1" v-if="$vuetify.breakpoint.smAndDown">İptal (Bu Ay)</span>
						</div>
						<div class="text-right text-no-wrap summary__content">
							<div>
								<h1 class="mb-1" :style="{ fontSize: boxTitleFontSize }">
									{{ summary.monthlyRejected }}
								</h1>
								<div class="subtitle-1 text-uppercase font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
									İptal (Bu Ay)
								</div>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row> -->
		<v-row class="text-center summary my-1" dense>
			<v-col cols="12" md="">
				<v-card color="secondary" dark flat @click="filterBind(sellStep.NEW_REQUEST, false)">
					<template slot="progress">
						<v-progress-linear height="4" indeterminate></v-progress-linear>
					</template>
					<div class="d-flex flex-no-wrap justify-space-between align-center" :class="boxPadding">
						<div class="text-center align-center d-flex">
							<v-avatar color="white" :size="avatarSize">
								<v-icon v-text="'fas fa-plus'" color="secondary" :size="iconSize"></v-icon>
							</v-avatar>
							<span class="ml-5 subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Yeni Talepler</span>
						</div>
						<div class="text-right text-no-wrap summary__content">
							<div>
								<h1 class="mb-1" :style="{ fontSize: boxTitleFontSize }">
									{{ summary.allNewRequest }}
								</h1>
								<div class="subtitle-1 text-uppercase font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
									Yeni Talepler
								</div>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col cols="12" md="">
				<v-card color="secondary" dark flat @click="filterBind(sellStep.PRE_EXAMINATION, false)">
					<template slot="progress">
						<v-progress-linear height="4" indeterminate></v-progress-linear>
					</template>
					<div class="d-flex flex-no-wrap justify-space-between align-center" :class="boxPadding">
						<div class="text-center align-center d-flex">
							<v-avatar color="white" :size="avatarSize">
								<v-icon v-text="'fas fa-eye'" color="secondary" :size="iconSize"></v-icon>
							</v-avatar>
							<span class="ml-5 subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Ön İncelemeler</span>
						</div>
						<div class="text-right text-no-wrap summary__content">
							<div>
								<h1 class="mb-1" :style="{ fontSize: boxTitleFontSize }">
									{{ summary.allPreExamination }}
								</h1>
								<div class="subtitle-1 text-uppercase font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
									Ön İncelemeler
								</div>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col cols="12" md="">
				<v-card color="secondary" dark flat @click="filterBind(sellStep.SCHEDULED, false)">
					<template slot="progress">
						<v-progress-linear height="4" indeterminate></v-progress-linear>
					</template>
					<div class="d-flex flex-no-wrap justify-space-between align-center" :class="boxPadding">
						<div class="text-center align-center d-flex">
							<v-avatar color="white" :size="avatarSize">
								<v-icon v-text="'fas fa-calendar-alt'" color="secondary" :size="iconSize"></v-icon>
							</v-avatar>
							<span class="ml-5 subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Randevular</span>
						</div>
						<div class="text-right text-no-wrap summary__content">
							<div>
								<h1 class="mb-1" :style="{ fontSize: boxTitleFontSize }">
									{{ summary.allScheduled }}
								</h1>
								<div class="subtitle-1 text-uppercase font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
									Randevular
								</div>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col cols="12" md="">
				<v-card color="secondary" dark flat @click="filterBind(sellStep.EXPERTISE, false)">
					<template slot="progress">
						<v-progress-linear height="4" indeterminate></v-progress-linear>
					</template>
					<div class="d-flex flex-no-wrap justify-space-between align-center" :class="boxPadding">
						<div class="text-center align-center d-flex">
							<v-avatar color="white" :size="avatarSize">
								<v-icon v-text="'fas fa-car-crash'" color="secondary" :size="iconSize"></v-icon>
							</v-avatar>
							<span class="ml-5 subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Ekspertizler</span>
						</div>
						<div class="text-right text-no-wrap summary__content">
							<div>
								<h1 class="mb-1" :style="{ fontSize: boxTitleFontSize }">
									{{ summary.allExpertise }}
								</h1>
								<div class="subtitle-1 text-uppercase font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
									Ekspertizler
								</div>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
			<v-col cols="12" md=""
				><v-card color="secondary" dark flat @click="filterBind(sellStep.PAYMENT, false)">
					<template slot="progress">
						<v-progress-linear height="4" indeterminate></v-progress-linear>
					</template>
					<div class="d-flex flex-no-wrap justify-space-between align-center" :class="boxPadding">
						<div class="text-center align-center d-flex">
							<v-avatar color="white" :size="avatarSize">
								<v-icon v-text="'fas fa-turkish-lira-sign'" color="secondary" :size="iconSize"></v-icon>
							</v-avatar>
							<span class="ml-5 subtitle-1" v-if="$vuetify.breakpoint.smAndDown">Ödemeler</span>
						</div>
						<div class="text-right text-no-wrap summary__content">
							<div>
								<h1 class="mb-1" :style="{ fontSize: boxTitleFontSize }">
									{{ summary.allPayment }}
								</h1>
								<div class="subtitle-1 text-uppercase font-weight-bold" v-if="$vuetify.breakpoint.mdAndUp">
									Ödemeler
								</div>
							</div>
						</div>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { sellStep } from '@/common/enums';

export default {
	name: 'CarInfoSummary',
	props: {
		summary: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			sellStep,
		};
	},
	computed: {
		...mapGetters(['gridPreset']),
		statusList() {
			return this.gridPreset?.statusList;
		},
		avatarSize() {
			return this.$vuetify.breakpoint.smAndDown ? '30px' : '60px';
		},
		iconSize() {
			return this.$vuetify.breakpoint.smAndDown ? '15px' : '30px';
		},
		boxPadding() {
			return this.$vuetify.breakpoint.smAndDown ? 'py-2 px-5' : 'pa-5';
		},
		boxTitleFontSize() {
			return this.$vuetify.breakpoint.smAndDown ? '25px' : '30px';
		},
	},
	methods: {
		filterBind(step, onlyThisMonth) {
			const status = this.statusList?.find((status) => status.id === step);
			this.$emit('filterStatus', status, onlyThisMonth);
		},
	},
};
</script>

<style lang="scss" scoped>
.summary {
	.count {
		font-weight: bold;
		font-size: 25px;
	}
}
</style>
